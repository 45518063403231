@import '~sass-rem';
@import 'common/styles/colors.scss';

.rowWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: rem(15px);
    overflow-y: auto;
    .priceRow {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        margin-top: rem(18px);
        &:first-of-type {
            margin-top: rem(5px);
        }
        .priceInput {
            margin-right: rem(18px);
            margin-bottom: 0;
        }
    }
}

.productTypeSelect {
    width: 50%;
    flex-grow: 0;
    margin-right: rem(10px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.select {
    margin-bottom: rem(20px);
}

.iconsHolder {
    width: 10%;
    display: flex;
    flex-direction: row;
    padding-top: rem(5px);
    .icon {
        cursor: pointer;
    }
}

.text {
    padding: 15px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: rem(10px);
    width: 100%;
}

.textField {
    width: 20%;
    margin-right: rem(10px);
}

.selectField {
    margin-right: rem(10px);
    width: 50%;
}

.inputField {
    margin-right: rem(10px);
    width: 15%;
}

.deleteIcon {
    color: gray;
    cursor: pointer;
}
.addButtonRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: rem(10px);
}
.menuProps {
    max-height: 250px;
}
.addButton {
    animation: pulse 1.5s ease-in-out infinite;
    @keyframes pulse {
        0% {
            transform: scale(0.9);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }
}
