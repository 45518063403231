@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    color: $white;
    background-color: $tooltip-header;
}
.generalInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 400px;
    padding: rem(30px) 0 rem(60px);
}
.header {
    width: 100%;
    font-weight: 600;
    margin: rem(10px) 0;
    &:first-of-type {
        margin-top: 0;
    }
}
.autocompleteWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: rem(20px);
}
.autocompleteFieldValues {
    padding: rem(10px) 0;
}
.autocompleteFieldValue {
    margin: rem(5px);
}
.saveButton {
    display: flex;
    flex-shrink: 0;
    text-transform: uppercase;
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}
