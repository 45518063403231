@import '~sass-rem';
@import 'common/styles/mixins.scss';
@import 'common/styles/colors.scss';

.toolbar {
    background-color: $tooltip-header;
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: rem(20px);
    border-bottom: 1px solid lightgray;
}

.sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: rem(10px);
    margin-bottom: rem(20px);
    border-bottom: 1px solid lightgray;
}

.customSelect {
    min-width: 50%;
    margin-bottom: rem(40px);
}

.ruleRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(20px);
}

.sectionName {
    min-width: 200px;
    margin-right: rem(20px);
    padding: rem(10px) 0;
    flex-shrink: 0;
}

.customTextFieldWrapper {
    flex-grow: 1;
    width: calc(100% - 220px);
}

.customTextField.customTextField {
    min-width: 370px;
    width: calc(50% - 40px);
    margin: rem(5px) rem(20px) rem(5px) 0;
}

.adsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: rem(60px);
}

.submitContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: rem(20px) 0 rem(12px);
    button {
        margin-left: rem(15px);
    }
}
