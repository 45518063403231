@import '~sass-rem';
@import 'common/styles/colors.scss';
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: rem(8px);
}

.titleWrapper {
    width: 200px;
    height: auto;
    margin-right: auto;
    .title {
        margin-right: rem(12px);
        line-height: rem(20px);
    }
}

.deleteIcon {
    cursor: pointer;
    margin-right: rem(5px);

    &:hover {
        color: rgb(165, 19, 19);
    }
}

.svgIcon {
    display: flex;
    cursor: pointer;
}
