@import '~sass-rem';
@import 'common/styles/colors.scss';
@import 'common/styles/mixins.scss';
.toolbar {
    background-color: $tooltip-header;
}

.container {
    padding: rem(60px) rem(80px);
}

.form {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.autocompleteWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: rem(20px);
}

.autocompleteFieldValues {
    padding: rem(10px) 0;
}

.autocompleteFieldValue {
    margin: rem(5px);
}

.uploadCover {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(30px);
    > label {
        position: relative;
        z-index: 900;
        display: block;
        margin-right: rem(20px);
    }
    .fileName {
        display: inline-block;
    }
}

.info {
    position: absolute;
    bottom: rem(55px);
    text-align: center;
    color: brown;
    font-weight: 500;
    width: 100%;
}

.select {
    margin-bottom: rem(15px);
}

.submitContainer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    width: calc(100% - 15rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid lightgray;
    padding: rem(20px) rem(18px);
    background-color: $white;
}
